<template>
    <div class="payment">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="查看详情" :visible.sync="payDialog" :closeOnClickModal='false' width="600px" @close="closeDialog()">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="支付信息" name="first">
                        <el-form style="margin-top:25px" v-if="teacherList.is_neibu!=1">
                            <el-form-item label="">
                                <div class="kuai">
                                    <span class="xing">* </span>
                                    支付方式:
                                </div>
                                <template>
                                  {{teacherList.trade_type}}
                                </template>
                                <!-- <template v-if="teacherList.trade_type!='NATIVE'">
                                    手机端支付
                                </template> -->
                            </el-form-item>

                            <el-form-item label="">
                                <div class="kuai">
                                    <span class="xing">* </span>
                                    支付金额:
                                </div>
                                <template>
                                    {{teacherList.amount_total}}
                                </template>
                            </el-form-item>
                            <el-form-item label="">
                                <div class="kuai">
                                    <span class="xing">* </span>
                                    完成时间:
                                </div>
                                <template>
                                    {{dateFtt("yyyy-MM-dd-hh:mm", new Date(teacherList.success_time))}}
                                </template>
                            </el-form-item>
                            <el-form-item label="" >
                                <div class="kuai">
                                    <span class="xing">* </span>
                                    支付单号:
                                </div>
                                <template>
                                    {{teacherList.out_trade_no}}
                                </template>
                            </el-form-item>
                        </el-form>
                        
                        <el-form style="margin-top:25px" v-if="teacherList.is_neibu==1">
                            <el-form-item label="">
                                <div class="kuai">
                                    <span class="xing">* </span>
                                    下单形式:
                                </div>
                                <template>
                                    内部下单
                                </template>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="退款信息" name="second" v-if="teacherList.refund!=null">
                        <el-form style="margin-top:25px">
                            <el-form-item label="" v-if="teacherList.payment_method !=2">
                                <div class="kuai">
                                    <span class="xing">* </span>
                                    支付方式:
                                </div>
                                <template v-if="teacherList.trade_type='NATIVE'">
                                    微信支付
                                </template>
                                <template v-if="teacherList.trade_type!='NATIVE'">
                                    手机端支付
                                </template>
                            </el-form-item>

                            <el-form-item label="">
                                <div class="kuai">
                                    <span class="xing">* </span>
                                    退款理由:
                                </div>
                                <template>
                                    {{teacherList.refund.reason}}
                                </template>
                            </el-form-item>
                            <el-form-item label="">
                                <div class="kuai">
                                    <span class="xing">* </span>
                                    退款状态:
                                </div>
                                <template>
                                    {{teacherList.refund.status}}
                                </template>
                            </el-form-item>
                            <el-form-item label="">
                                <div class="kuai">
                                    <span class="xing">* </span>
                                    完成时间:
                                </div>
                                <template>
                                    {{dateFtt("yyyy-MM-dd-hh:mm", new Date(teacherList.refund.success_time))}}
                                </template>
                            </el-form-item>
                            <el-form-item label="" v-if="teacherList.payment_method !=2">
                                <div class="kuai">
                                    <span class="xing">* </span>
                                    退款账户:
                                </div>
                                <template>
                                    {{teacherList.refund.user_received_account}}
                                </template>
                            </el-form-item>
                            <el-form-item label="">
                                <div class="kuai">
                                    <span class="xing">* </span>
                                    退款金额:
                                </div>
                                <template>
                                    {{teacherList.refund.total}}
                                </template>
                            </el-form-item>
                            <el-form-item label="">
                                <div class="kuai">
                                    <span class="xing">* </span>
                                    支付结果:
                                </div>
                                <template>
                                    {{teacherList.trade_state_desc}}
                                </template>
                            </el-form-item>
                            <el-form-item label="" v-if="teacherList.payment_method ==2">
                                <div class="kuai">
                                    <span class="xing">* </span>
                                    支付方式:
                                </div>
                                <template>
                                    {{teacherList.trade_type}}
                                </template>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog" class="success button">取 消</el-button>
                    <el-button type="danger" style="padding:10px;width:85px" class="tijiao primary button" v-if="status== 2 || status==3 || status==4" @click="onrefund(teacherList)"> 退款</el-button>
                    <el-button type="primary" @click="closeDialog" class="tijiao primary button" v-else>确 定</el-button>
                </div>
            </el-dialog>
            <refund ref="refund"></refund>
        </div>
    </div>
</template>

<script>
import refund from "./refund.vue";
export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("TZ-USER")).admin_token,
            payDialog: false, //操控弹窗展示
            teacherList: [], //配音师列表
            order_id: "", //订单id
            activeName: "first",
            status: "",
        };
    },
    mounted() {},
    components: {
        refund,
    },
    methods: {
        handleClick(tab, event) {
        },
        // 点击退款
        onrefund(data) {
            this.$refs.refund.onyunyingtan(data);
        },
        onpayment(data) {
            this.payDialog = true;
            this.status = data.status;
            var param = {
                order_id: data.id,
            };
            this.$service.post(this.$api.getDubOrderPayInfo, param, (res) => {
                if (res.code == "200") {
                    this.teacherList = res.data;
                }
            });
        },
        parent() {
            this.payDialog = false;
            this.$parent.ongetInternalOrderList();
        },

        closeDialog() {
            // this.dubber="";
            this.payDialog = false;
        },
        // 提交编辑
        tijiao() {
            // var param = {
            //    order_id:this.order_id,
            //    mixer_id:this.dubber
            // }
            // if (param.mixer_id == "") {
            //     this.$message({ message: "请选择配音师", type: "warning" });
            //     return;
            // }
            // this.$service.post(this.$api.alterDubOrderMixer, param, (res) => {
            //     if (res.code == "200") {
            //         this.$message({
            //             message: "修改成功",
            //             type: "success",
            //         });
            //         this.yunyingtan = false;
            //         this.dubber="";
            //         this.$parent.ongetInternalOrderList();
            //     }
            // });
        },
    },
};
</script>

<style lang="scss" scoped>
.payment {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 200px;
        text-align: center;
        display: inline-block;
        font-weight: bold;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    .img {
        width: 148px;
        height: 148px;
        margin-right: 20px;
    }
    //
}
</style>
<style lang="scss">
.payment {
    .upload-demo {
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-form-item__content {
        display: flex;
    }
    .el-radio {
        line-height: 40px;
    }
    .right {
        // display: flex;
    }
}
</style>