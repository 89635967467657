<template>
    <div class="shooting">
        <inquiry ref="inquiry"></inquiry>
        <div class="padd20">
            <div class="duoshao">
                <div style="margin-left:15px">配音师订单列表 </div>
                <el-button size="primary" slot="reference" type="primary" @click="derive()" style="margin-left:25px">批量导出</el-button>
                <div class="lvtotal">总条数：{{lvtotal}} 条
                    <span style="margin-left:20px">每页显示:</span>
                    <el-select v-model="limit" placeholder="20" @change="selectTrigger()" style="width:80px; margin:0px 15px">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    条
                    <div class="lvtotal riri"></div>
                </div>
            </div>
            <div v-if="tableData !=''">
                <div class="list" v-for='(item,index) in tableData' :key="item.index" :id="index">
                    <div class="title tits" :style="'background:' + (item.order_status==1 ? '#e5f3f7' : item.order_status==2 ? '#e6f1e1' : item.order_status==3 ? '#f7f0e5' : '')">
                        <div>
                            <input type="checkbox" :value="item.id" v-model="postIdList" style="margin-right:25px; margin-top:8px">
                            <span style="width:100px;display: inline-block;">ID：{{item.id}}</span>
                            <span style="width:260px;display: inline-block;">订单号：{{item.order_id}}</span>
                            <span style="width:240px;display: inline-block;overflow:hidden;white-space:nowrap;text-overflow: ellipsis;">下单人：<img :src="item.server_wechat?item.server_wechat.wechat_head:''" alt="" class="img"> {{ item.server_wechat ? Base64.decode(item.server_wechat.wechat_name) : '- -'}} </span>
                            <span style="width:260px;display: inline-block;">创建时间：{{item.created_at}}</span>
                            <span style="width:480px;display: inline-block;">完成时间：{{item.start_time}} - {{item.end_time}}</span>
                            <!-- <span style="width:200px;display: inline-block;">付款时间：{{item.pay_time?item.pay_time:'- -'}}</span> -->
                            <span style="width:70px;display: inline-block;">订单状态：</span>
                            <el-dropdown style="margin-left:0px !important;" v-if="item.status_info.dingdan_status=='未接单' ||item.status_info.dingdan_status=='已支付'">
                                <div class="el-dropdown-link dropd" style=" color:#fff">
                                    <span style="background: #0075F7; border-radius: 5px; width:60px; text-align: center;">{{item.status_info.dingdan_status}}</span>
                                </div>
                                <el-dropdown-menu slot="dropdown" placement="bottom" trigger="click" popper-class="class-menu">
                                    <el-dropdown-item @click.native='star(item)'><span>配音中</span></el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-tag type="info" effect="dark" v-if="item.status_info.dingdan_status=='未支付'||item.status_info.dingdan_status=='已退款'||item.status_info.dingdan_status=='已失效'">{{item.status_info.dingdan_status}}</el-tag>
                            <el-tag type="success" effect="dark" v-if="item.status_info.dingdan_status=='已支付'||item.status_info.dingdan_status=='已完成'||item.status_info.dingdan_status=='已发配音'">{{item.status_info.dingdan_status}}</el-tag>
                            <el-tag type="warning" effect="dark" v-if="item.status_info.dingdan_status=='配音中'">{{item.status_info.dingdan_status}}</el-tag>
                            <el-tag type="danger" effect="dark" v-if="item.status_info.dingdan_status=='不满意'">{{item.status_info.dingdan_status}}</el-tag>
                            <!--                         
                            <el-dropdown>
                                <span style="width:90px;display: inline-block;">订单状态：</span>
                                <div class="el-dropdown-link" style="margin-left:0px; color:#fff">
                                    <span v-if="item.status==1" style="background: #07D258;  border-radius: 5px; width:60px; text-align: center;">{{item.status==1 ? '一星 ' : item.status==2 ? '二星':item.status==3 ? '三星':''}}</span>
                                    <span v-if="item.status==2" style="background: #0075F7;  border-radius: 5px;width:60px;  text-align: center;">{{item.status==1 ? '一星 ' : item.status==2 ? '二星':item.status==3 ? '三星':''}}</span>
                                    <span v-if="item.status==3" style="background: #E61B1B;  border-radius: 5px;width:60px;  text-align: center;">{{item.status==1 ? '一星 ' : sitem.status==2 ? '二星':item.status==3 ? '三星':''}}</span>
                                </div>
                                <el-dropdown-menu slot="dropdown" placement="bottom" trigger="click" popper-class="class-menu">
                                    <el-dropdown-item @click='star(1,item)'><span>一星</span></el-dropdown-item>
                                    <el-dropdown-item @click='star(2,item)'><span>二星</span></el-dropdown-item>
                                    <el-dropdown-item @click='star(3,item)'><span>三星</span></el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown> -->

                        </div>
                        <div></div>
                        <!-- <div>
                        <el-button size="primary" slot="reference" type="primary" @click="follow(item.id,item)" style="margin-left:0px">查看详情</el-button>
                    </div> -->
                    </div>

                    <div class="center">

                        <div class="one">
                            <div class="flex">
                                <div>配音师</div>
                                <p>{{item.mixer.mixer ? item.mixer.mixer : '- -'}}</p>
                            </div>
                            <div class="flex">
                                <div>下单人电话
                                </div>
                                <p>{{item.server_user ? item.server_user.account : '- -'}}</p>
                            </div>
                            <div class="flex" style="margin-bottom:10px">
                                <div>客户反馈</div>
                                <p v-if="item.record_status==1" style="cursor: pointer;">
                                    <el-tag effect="dark">已上传</el-tag>
                                </p>
                                <p v-else-if="item.record_status==2" style="cursor: pointer;">
                                    <el-tag type="info" effect="dark">未上传</el-tag>
                                </p>
                                <p v-else-if="item.record_status==3" style="cursor: pointer;">
                                    <el-tag type="danger" effect="dark">不满意</el-tag>
                                </p>
                            </div>
                        </div>

                        <div class="one">
                            <!-- <div class="flex">
                            <div>配音文案</div>
                            <el-tooltip class="item" effect="dark" popper-class="testtooltip" :content="item.content" placement="top-start">
                                <p class="xians"> {{item.content}}</p>
                            </el-tooltip>
                        </div> -->
                            <div class="flex">
                                <div>文案字数</div>
                                <p>{{item.content_word_count}}</p>
                            </div>
                            <div class="flex">
                                <div>配音时长</div>
                                <p>{{item.duration ? item.duration : '- -'}}</p>
                            </div>
                            <div class="flex">
                                <div>支付状态</div>
                                <p style="cursor: pointer;"> <span v-if="item.status==1">
                                        <el-tag type="info" effect="dark">未支付</el-tag>
                                    </span>
                                    <span v-else-if="item.status==2">
                                        <el-tag type="success" effect="dark">已支付</el-tag>
                                    </span>
                                    <span v-else-if="item.status==3">
                                        <el-tag type="success" effect="dark">已支付</el-tag>
                                    </span>
                                    <span v-else-if="item.status==4">
                                        <el-tag type="success" effect="dark">已支付</el-tag>
                                    </span>
                                    <span v-else-if="item.status==5">
                                        <el-tag type="danger" effect="dark">已退款</el-tag>
                                    </span>
                                    <span v-else-if="item.status==6">
                                        <el-tag type="info" effect="dark">已失效</el-tag>
                                    </span>
                                </p>
                            </div>

                        </div>
                        <!--  -->
                        <div class="one">
                            <div class="flex">
                                <div>价格</div>
                                <p>{{item.cost}}</p>
                            </div>
                            <div class="flex">
                                <div>支付积分</div>
                                <p>{{item.integral}}</p>
                            </div>
                            <div class="flex">
                                <div>支付方式</div>
                                <p style="cursor: pointer; text-indent: 0em; margin-left:20px">
                                    <span v-if="item.payment_method==1">
                                        <el-tag type="success">微信支付</el-tag>
                                    </span>
                                    <span v-else>
                                        <el-tag type="warning">积分支付</el-tag>
                                    </span>
                                </p>
                            </div>
                            <!-- <div class="flex">
                            <div style="width:210px">参考视频链接</div>
                            <p @click="onvideo(item.video_link.url_link)" class="chaoxian">
                                <a href="">点击查看</a>
                            </p>
                        </div> -->
                        </div>
                        <!--  -->
                        <div class="one">
                            <!-- <div class="flex">
                            <div>配音文案</div>
                            <el-tooltip class="item" effect="dark" popper-class="testtooltip" :content="item.content" placement="top-start">
                                <p class="xians"> {{item.content}}</p>
                            </el-tooltip>
                        </div> -->
                            <div class="flex">
                                <div>IP</div>
                                <p>{{item.ip}}</p>
                            </div>
                            <div class="flex">
                                <div>视频案例</div>
                                <p @click="onvideo(item.video_case.file_url)" class="chaoxian" v-if="item.video_case!=null">
                                    <a href="javascript:;">点击查看</a>
                                </p>
                                <p v-if="item.video_case==null">- -</p>
                            </div>
                            <div class="flex">
                                <div>备注</div>
                                <el-tooltip class="item" effect="dark" popper-class="testtooltip" :content="item.remark" placement="left-start">
                                    <p class="xians">{{item.remark ? item.remark : '- -'}}</p>
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="auto">
                            <el-button type="primary" @click="amend(item.id)">修改配音师</el-button>
                            <el-button type="primary" @click="payment(item)">支付详情</el-button>

                            <el-button type="warning" @click="tickling(item.id,item)">配音反馈</el-button>
                            <!-- <el-button type="success" style="margin-left:20px; margin-top:25px">上传配音</el-button> -->
                        </div>
                    </div>
                    <div class="wenan">
                        <div class="flex">
                            <div>
                                <span class="peiyin">
                                    配音文案：
                                    <el-button type="success" class="copy" @click="doCopy(item.content)" style=" position: absolute;bottom: -10px; right: 20px;">一键复制</el-button>
                                </span>
                                <!-- <el-tooltip class="item" effect="dark" popper-class="testtooltip" :content="item.content" placement="top-start"> -->
                                <p class="content">{{item.content ? item.content : '- -'}}</p>
                                <!-- </el-tooltip> -->
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div v-if="tableData ==''" class="zanwu">
                暂无数据
            </div>
        </div>
        <!-- 分页 -->
        <div class="fenye">
            <el-pagination class="lv-page" :page-size="limit" :current-page.sync="currentPage" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next, jumper" :total="lvtotal" hide-on-single-page></el-pagination>
        </div>
        <amend ref="amend"></amend>
        <payment ref="payment"></payment>
        <tickling ref="tickling"></tickling>
        <uploading ref="uploading"></uploading>
        <seed ref="seed"></seed>
    </div>
</template>

<script>
import amend from "./amend.vue";
import payment from "./payment.vue";
import inquiry from "./inquiry.vue";
import tickling from "./tickling.vue";
import Uploading from "./uploading.vue";
import seed from "./seed.vue";
export default {
    data() {
        return {
            paramData: {},
            tableData: [],
            lvtotal: null,
            limit: 20, //一页多少条
            currentPage: 1, //当前页
            message: "",
            postIdList: [], //多选
            options: [
                {
                    value: 25,
                    label: "25",
                },
                {
                    value: 50,
                    label: "50",
                },
                {
                    value: 100,
                    label: "100",
                },
            ],
        };
    },
    methods: {
        //修改状态
        star(data) {
            if (data.status == 2) {
                var param = {
                    order_id: data.id,
                    status: 3,
                };
                this.$service.post(this.$api.setOrderStatus, param, (res) => {
                    if (res.code == "200") {
                        this.ongetInternalOrderList();
                         this.$message.success("操作成功");
                    }
                });
            } else {
                this.$message.error("操作失败");
            }
        },
        //反馈
        tickling(id, data) {
            if (data.record_status == 1) {
                this.$refs.tickling.ontickling(id, data);
            } else if (data.record_status == 2) {
                this.$refs.uploading.onUploading(id, data);
            } else {
                this.$refs.tickling.ontickling(id, data);
            }
        },
        //支付详情
        payment(data) {
            this.$refs.payment.onpayment(data);
        },
        //修改配音师
        amend(id) {
            this.$refs.amend.onyunyingtan(id);
        },
        //一键复制
        doCopy: function (val) {
            this.message = val;
            // console.log(val)
            var that = this;
            // this.dataProcessing(val);

            this.$copyText(this.message).then(
                function (e) {
                    that.$message.success("复制成功");
                },
                function (e) {
                    that.$message.error("复制失败");
                }
            );
        },
        //   获取订单列表
        ongetInternalOrderList() {
            var param = this.paramData;
            param.page = this.currentPage;
            param.per_page=this.limit;
            this.$service.get(this.$api.getDubOrderList, param, (res) => {
                if (res.code == "200") {
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
        //批量导出
        derive() {
            var paramData = this.paramData;
            delete paramData.page;
            if (
                paramData.order_id == undefined &&
                paramData.mixer_id == undefined &&
                paramData.record_status == undefined &&
                paramData.status == undefined &&
                paramData.beginTime == undefined &&
                paramData.endTime == undefined &&
                this.postIdList.length == 0
            ) {
                this.$message({ message: "请搜索导出条件", type: "warning" });
                return;
            }
            window.open(
                this.$api.dubOrderBatchExport +
                    "?order_id=" +
                    (paramData.order_id ? paramData.order_id : "") +
                    "&mixer_id=" +
                    (paramData.mixer_id ? paramData.mixer_id : "") +
                    "&record_status=" +
                    (paramData.record_status ? paramData.record_status : "") +
                    "&status=" +
                    (paramData.status ? paramData.status : "") +
                    "&beginTime=" +
                    (paramData.beginTime ? paramData.beginTime : "") +
                    "&endTime=" +
                    (paramData.endTime ? paramData.endTime : "") +
                    "&arr_id=" +
                    (this.postIdList ? this.postIdList : "") +
                    "&token=" +
                    JSON.parse(localStorage.getItem("TZ-USER")).admin_token
            );
        },
        // 查看详情
        follow(id, row) {
            this.$router.push({ path: "/subproject", query: { id: id } });
        },
        //显示条数改变
        selectTrigger() {
            this.currentPage = 1;
            this.paramData.per_page = this.limit;
            this.ongetInternalOrderList();
        },
        // 分页
        DialogCurrentChange(val) {
            this.currentPage = val;
            this.$router.push({
                path: "../ErLangcha/dubbingorders",
                query: { currentPage: val },
            });
            this.ongetInternalOrderList();
        },

        //接收搜索参数
        onAllSearch(data) {
            this.paramData.beginTime = data.beginTime; //开始时间
            this.paramData.endTime = data.endTime; //结束时间
            this.paramData.mixer_id = data.mixer_id; //配音师id
            this.paramData.order_id = data.order_id; //订单号
            this.paramData.record_status = data.record_status; //客户状态
            this.paramData.status = data.status; //订单状态
            this.ongetInternalOrderList();
        },
        //查看视频
        onvideo(video) {
            this.$refs.seed.confitionDialog = true;
            this.$refs.seed.getvideo(video);
            // window.open("http://192.168.111.24/" + video);
        },
    },

    mounted() {
        if (this.$route.query.currentPage) {
            this.currentPage = this.$route.query.currentPage * 1;
        }
        this.ongetInternalOrderList();
    },
    components: {
        amend,
        payment,
        inquiry,
        tickling,
        Uploading,
        seed,
    },
};
</script>

<style lang="scss" scoped>
.shooting {
    .list {
        border: 1px solid #ccc;
        margin-bottom: 25px;
    }
    .title {
        display: flex;
        line-height: 30px;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        margin: 0 auto;
        padding: 10px 20px;
        div {
            display: flex;
            margin-left: 25px;
            height: 30px !important;
            line-height: 30px;
        }
        .dropd {
            margin-left: 0px;
            cursor: pointer;
        }
        .el-tag {
            width: 60px;
            text-align: center;
            height: 30px;
            line-height: 30px;
        }
        // div:nth-child(2) {
        //     margin-left: 160px;
        // }
        // div:nth-child(3) {
        //     margin-left: 240px;
        // }
        // div:nth-child(4) {
        //     margin-left: 240px;
        // }
        .beizhu {
            display: flex;
            text-align: left;
            span {
                width: 50px;
            }
            div {
                margin-left: 0px;
            }
        }
    }
    .tits {
        background: #eef6fe;
    }
    .tit {
        border-bottom: 0px solid #ccc;
        border-top: 1px solid #ccc;
    }
    .center {
        display: flex;
        margin-left: 20px;
        margin-right: 20px;
        .one {
            width: 430px;
            border-right: 1px solid #ccc;
            padding-top: 10px;
        }
        .flex {
            display: flex;
            line-height: 26px;
            margin-bottom: 10px;
            div {
                width: 160px;
                text-indent: 2em;
                font-weight: bold;
            }
            p {
                width: 100%;
                text-indent: 2em;
            }
        }
        .jin p {
            width: 50%;
        }
        .flexs {
            display: flex;
            div {
                width: 147px !important;
            }
        }
    }
    .wenan {
        width: 100%;
        border-top: 1px solid #ccc;
    }
    .auto {
        margin: 0 auto;
        margin-top: 40px;
        width: 390px;
        display: flex;
        button{
            height: 33px;
        }
    }
    .hei {
        height: 34px;
    }
    .rig p {
        border-right: 0px !important;
    }
    .flock-load {
        max-height: 200px;
        width: 100%;
        overflow-y: auto;
        display: inline-block;
    }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    .fromkuang {
        position: relative;
    }
    // .cha {
    //     position: absolute;
    //     top: 20px;
    //     right: 25px;
    //     text-align: center;
    //     span {
    //         display: block;
    //         margin-top: 10px;
    //         cursor: pointer;
    //         color: #0075f7;
    //         font-weight: bold;
    //     }
    //     .el-button--primary {
    //         margin: 0 !important;
    //     }
    // }
    .ddv {
        line-height: 34px;
    }
    .xians {
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        cursor: pointer;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1 !important;
        overflow: hidden;
    }
    .zanwu {
        text-align: center;
        color: #909399;
    }
    .duoshao {
        line-height: 35px;
    }
    .lvtotal {
        .el-select .el-input {
            margin-left: 20px;
            margin-right: 20px;
            width: 90px !important;
        }
    }
    .imgss {
        cursor: pointer;
        margin-top: -60px;
        width: 100px;
        height: 100px;
        border: 0px;
    }
    img {
        width: 30px;
        height: 30px;
        margin: 0px 10px;
    }
    .chaoxian {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        word-break: break-all;
    }
    .content {
        width: 85%;
        margin: 0px auto;
        padding: 10px 10px;
        padding-bottom: 10px;
        line-height: 24px;
        cursor: pointer;
        margin-top: -30px;
    }
    .peiyin {
        margin-left: 20px;
        margin-top: 10px;
        display: block;
        text-indent: 2em;
        font-weight: bold;
        position: relative;
    }
}
</style>
<style lang="scss">
.shooting {
    .el-tag--dark.el-tag--danger {
        text-indent: 0 !important;
    }
    .el-tag--dark {
        text-indent: 0 !important;
    }
    .el-tag--warning {
        text-indent: 0 !important;
    }
    .el-textarea__inner {
        width: 95%;
        display: block;
        margin: 10px auto;
    }
    .el-tag {
        line-height: 26px;
        height: 26px;
        padding: 0px 5px;
    }
}
.testtooltip {
    text-align: center;
    width: 300px;
}
</style>

