<template>
    <div class="payment">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="配音反馈" :visible.sync="ticklingDialog" :closeOnClickModal='false' width="1200px" @close="closeDialog()">
                <el-form style="margin:25px 0px;">
                    <div style="margin-bottom:25px" v-if="record_status==3">
                        <el-button size="primary" slot="reference" type="primary" @click="derive()" style="margin-left:25px">上传配音</el-button>
                    </div>

                    <el-table :data="teacherList" style="width: 100%">
                        <el-table-column prop="title" label="标题" width="250">
                        </el-table-column>
                        <el-table-column prop="product_name" label="产品名称">
                        </el-table-column>
                        <el-table-column prop="remark" label="反馈内容" width="250">
                        </el-table-column>
                        <el-table-column prop="created_at" label="创建时间" width="180">
                        </el-table-column>
                        <el-table-column prop="is_sati" label="评价">
                            <template slot-scope="scope">
                                <p v-if="scope.row.is_sati==1" style="cursor: pointer;">
                                    <el-tag effect="dark">满意</el-tag>
                                </p>
                                <p v-else-if="scope.row.is_sati==2" style="cursor: pointer;">
                                    <el-tag type="danger" effect="dark">不满意</el-tag>
                                </p>
                                <p v-else-if="scope.row.is_sati" style="cursor: pointer;">
                                    <el-tag type="success" effect="dark">未反馈</el-tag>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="is_send" label="是否发送">
                            <template slot-scope="scope">
                                <p v-if="scope.row.is_send==1" style="cursor: pointer;">
                                    <el-tag type="success" effect="dark">已发送</el-tag>
                                </p>
                                <p v-else-if="scope.row.is_send==2" style="cursor: pointer;">
                                    <el-tag type="info" effect="dark">未发送</el-tag>
                                </p>

                            </template>
                        </el-table-column>

                        <el-table-column prop="status" label="下载">
                            <template slot-scope="scope">
                                <p style="cursor: pointer;">
                                    <el-tag type="success" effect="dark" @click="download(scope.row)">点击下载</el-tag>
                                </p>
                            </template>
                        </el-table-column>
                    </el-table>

                </el-form>
                <!-- <div slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button>
                </div> -->
                <uploading ref="uploading" @ontick="ontick($event)"></uploading>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Uploading from "./uploading.vue";
export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("TZ-USER")).admin_token,
            ticklingDialog: false, //操控弹窗展示
            teacherList: [], //配音师列表
            order_id: "", //订单id
            record_status: "",
            deriveData: "",
            deriveId: "",
        };
    },
    mounted() {},
    components: {
        Uploading,
    },

    methods: {
        //上传反馈
        derive() {
            this.$refs.uploading.onUpload(this.deriveId, this.deriveData);
        },
        download(data) {
            window.open(data.file.file_url);
        },
        // 获取配音反馈
        ontickling(id, data) {
            this.record_status = data.record_status;
            this.deriveData = data;
            this.deriveId = id;
            this.ticklingDialog = true;
            // this.order_id=id;
            // // this.getDubOrderMixerByLevel();
            var param = {
                order_id: id,
            };
            this.$service.post(this.$api.getDubRecordsList, param, (res) => {
                if (res.code == "200") {
                    this.teacherList = res.data;
                }
            });
        },
        // 获取配音反馈
        ontick(data) {
            var param = {
                order_id: data,
            };
            this.$service.post(this.$api.getDubRecordsList, param, (res) => {
                if (res.code == "200") {
                    this.teacherList = res.data;
                }
            });
        },
        closeDialog() {
            this.ticklingDialog = false;
        },
        // 提交编辑
        tijiao() {
            // var param = {
            //    order_id:this.order_id,
            //    mixer_id:this.dubber
            // }
            // if (param.mixer_id == "") {
            //     this.$message({ message: "请选择配音师", type: "warning" });
            //     return;
            // }
            // this.$service.post(this.$api.alterDubOrderMixer, param, (res) => {
            //     if (res.code == "200") {
            //         this.$message({
            //             message: "修改成功",
            //             type: "success",
            //         });
            //         this.yunyingtan = false;
            //         this.dubber="";
            //         this.$parent.ongetInternalOrderList();
            //     }
            // });
        },
    },
};
</script>

<style lang="scss" scoped>
.payment {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    .img {
        width: 148px;
        height: 148px;
        margin-right: 20px;
    }
    //
}
</style>
<style lang="scss">
.payment {
    .upload-demo {
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-form-item__content {
        display: flex;
    }
    .el-radio {
        line-height: 40px;
    }
}
</style>